<template>
<!-- 易货详情 -->
  <div id="page">
    <div class="header">
      <header-base :sear="'hide'"></header-base>
    </div>

    <div class="main">
      <div class="header">
        <div class="left_img">
          <el-carousel height="400"
          ref="carousel"
          :interval="4000" arrow="hover">
            <el-carousel-item v-for="item in bannerImg" :key="item">
              <img :src="item" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="center_car">
          <div class="center_title">
            <span class="yushouCont">{{$t('m.barter.existing_products')}}</span>
            <h4 class="titlefont">{{product.name}}</h4>
          </div>
          <el-form ref="datalist" :model="datalist" label-width="500px">
            <el-form-item :label="$t('m.barter_detail.Self_estimated_price')" :label-width="$t('m.barter_detail.Delivery_width')">
              <span class="saleclass">
                {{currencySymbol+product.price+'/'+product.unit}}
              </span>
            </el-form-item>
            <div class="itemdiv">
              <span class="itemdiv_span">
                {{$t('m.barter.intended_exchange_commodity')}}：
                <strong>{{skuStockList.demandProductName+''}}</strong></span>
            </div>
            <el-form-item :label="$t('m.reservation_varietiy_details.Stock')" label-width="100px">
              <span class="stock_num">{{product.stock + product.unit}}</span>
            </el-form-item>
            <el-form-item :label="$t('m.barter_detail.period_of_validity')" :label-width="$t('m.barter_detail.Delivery_width')">
              <span>{{product.offerExpiryDate}}</span>
            </el-form-item>
            <el-form-item :label="$t('m.barter_detail.place_of_delivery')" :label-width="$t('m.barter_detail.Delivery_width')">
              <span>{{product.deliveryPlace}}</span>
            </el-form-item>
            <el-form-item :label="$t('m.barter_detail.Conversion_ratio')" :label-width="$t('m.barter_detail.Delivery_width')">
              <span>{{skuStockList.exchangeRatio}}</span>
            </el-form-item>
            <el-form-item :label="$t('m.barter_detail.Logistics_compensation')" :label-width="$t('m.reservation_varietiy_details.Delivery_width')">
              <span>{{currencySymbol+product.freightChina}}</span>
            </el-form-item>
            <el-form-item :label="$t('m.barter_detail.handling_costs')" :label-width="$t('m.barter_detail.Delivery_width')">
              <span>{{currencySymbol+skuStockList.carryMoney}}</span>
            </el-form-item>
            <el-form-item label-width="0px">
              <span class="zhuanshouspan">
                <el-icon><warning-filled /></el-icon>
                {{$t('m.barter_detail.Advance_deposit')+currencySymbol+skuStockList.earnestMoney}}
              </span>
            </el-form-item>
          </el-form>
          <span class="btnspan">
            <div class="btn_div">
              <button class="gobutton gm" :style="`left:${$t('m.reservation_varietiy_details.attentionLeft')}`"
                  @click="toBuyPage">
                  {{$t('m.barter_detail.To_generate_an_order')}}
              </button>
              <button class="gobutton gz" :style="`left:${$t('m.reservation_varietiy_details.purchaseLeft')}`"
                  @click="likeVarietiy">
                  {{$t('m.reservation_varietiy_details.attention')}}
              </button>
            </div>
          </span>
        </div>
        <div class="right_test">
          <h3 class="righttitle">{{$t('m.barter_detail.Predict_whether_the_goods_will_be_traded')}}</h3>
          <el-form class="right_form"
          label-suffix=":"
          ref="datalist" label-width="259px">
            <el-form-item :label="$t('m.barter.intended_exchange_commodity')" label-width="100px">
              <span>{{skuStockList.demandProductName}}</span>
            </el-form-item>
            <el-form-item :label="$t('m.barter_detail.Pseudo_easy_price')" label-width="100px">
              <el-input v-model="thisRightTest.price"
              size="mini"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.barter_detail.want_to_trade_inventory')" label-width="100px">
              <el-input v-model="thisRightTest.stock"
              size="mini"></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.barter_detail.Advance_deposit')" label-width="100px">
              <span>{{currencySymbol+allPrice}}</span>
            </el-form-item>
          </el-form>
          <div class="jisuan">
            <el-button class="gobutton"
            :loading="loading"
            @click="niprice">
                {{$t('m.barter_detail.Estimate_whether_it_will_be_able_to_trade')}}
            </el-button>
          </div>
          <!-- <div class="heji">
            预计总货款：<span>
              {{product.currencySymbol}}
              {{Number(allPrice+thisRightTest.yutivalue*thisRightTest.weightvalue).toFixed(1)}}
            </span>
          </div> -->
        </div>
      </div>
      <div class="detail">
        <div class="supplier_message">
          <h3>{{$t('m.reservation_varietiy_details.Supplier_file')}}</h3>
          <p class="supname">{{companyInfo.companyName}}</p>
          <!-- <span class="supgood">好评率：{{(99.5561).toFixed(2)}}%</span> -->
          <div class="supxiangxi">
            <div class="suplogo">
              <img :src="companyInfo.logo" alt="">
            </div>
            <div class="xxdiv">
              <el-icon><location /></el-icon>
              <span>{{companyInfo.address}}</span></div>
            <div class="xxdiv">
              <el-icon><phone-filled /></el-icon>
              {{companyInfo.phoneNumber}}</div>
            <div class="xxdiv">
              <el-icon><avatar /></el-icon>
              <el-popconfirm
                confirm-button-text="是的"
                cancel-button-text="再考虑一下"
                icon="InfoFilled"
                icon-color="#626AEF"
                :title="product.focusOnMerchant==1?'你要取关该供应商吗?':'你要关注该供应商吗?'"
                @confirm="confirmEvent"
                @cancel="cancelEvent"
              >
                <template #reference>
                  <el-button size="mini">{{product.focusOnMerchant==1?$t('m.reservation_varietiy_details.followed'):$t('m.reservation_varietiy_details.attention')}}</el-button>
                </template>
              </el-popconfirm>
            </div>
            <div class="xxdiv">
              <el-icon><ChatDotRound /></el-icon>
              <!-- <el-button size="mini" @click="goToMessage()">联系</el-button> -->
              <CreateChat :productData='{
                productInfo: this.productInfo,
                merchantId: this.product.merchantId,
                productId:this.product.id,
                companyName:this.companyInfo.companyName
              }' />
            </div>
          </div>
        </div>

        <div class="supplier_commodity">
          <h2>{{$t('m.reservation_varietiy_details.Coffee_details')}}</h2>
          <div class="commodity_message">
            <el-breadcrumb separator="|">
              <el-breadcrumb-item
              v-for="i in commMess" :key="i">
                <div class="com_body">
                  <span>{{i.cont}}</span>
                  <p>{{i.title}}</p>
                </div>
              </el-breadcrumb-item>
              <!-- <el-breadcrumb-item>promotion management</el-breadcrumb-item> -->
            </el-breadcrumb>
          </div>
          <div class="commodity_content">
            <p class="cont_message">
              <!-- {{product.description}} -->
            </p>
            <div class="cont_img" v-html="product.detailHtml">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="divfooter">
        <Footer/>
    </div>
  </div>
</template>

<script>
import Footer from '../index/Footer.vue'
import CreateChat from '../../components/chatroom/createChat.vue'
import HeaderBase from '../../components/HeaderBase.vue'

// import { toRaw } from 'vue'
import { onMounted, reactive, ref } from 'vue-demi'
import http from '../../api/http.js'
import { ElMessage, ElNotification } from 'element-plus'
import { getStore } from '../../tools/storage'

// 查询详情
const pmsexchangeproduct = (data) => {
  return http.get('/mall-portal/portal/pmsexchangeproduct/detailById', data)
}
// 摘单
const generateExchangeOrder = (data) => {
  return http.post('/mall-order/order/generateExchangeOrder', data, false)
}
// 关注商品接口
const followTheProduct = (data) => {
  return http.post('/mall-portal/mall/pmsfollowtheproduct/followTheProduct', data)
}
// 关注店铺接口
const followTheStore = (data) => {
  return http.post('/mall-portal/mall/pmsfollowthestore/followTheStore', data)
}
// 取消关注店铺
const unpmsfollowthestore = (data) => {
  return http.post('/mall-portal/mall/pmsfollowthestore/deleteByMerchantId', data)
}
export default {
  name: 'BarterDetail',
  data () {
    return {
      bannerImg: [
        'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/banner01/banner01.png',
        'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/banner01/banner02.jpg',
        'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/banner01/banner03.jpg'
      ], // 暂时图片
      detailData: '', // 存储所有数据
      product: '',
      companyInfo: '',
      skuStockList: [], // 规格？

      thisStock: 0, // 选择的规格（库存）
      currencySymbol: '',

      atime: [],
      loading: ref(false),
      thisRightTest: reactive({
        price: 0,
        stock: 0
      }), // 右侧预估价格
      payoption: [{}], // 付款比例
      // daikuan: 0,
      yunfei: 0,
      allPrice: ref(0), // 预估合计
      commMess: [
        { title: this.$t('m.reservation_varietiy_details.brand'), cont: '' },
        { title: this.$t('m.reservation_varietiy_details.date_issued'), cont: '' },
        { title: this.$t('m.reservation_varietiy_details.storage_life'), cont: '12个月' },
        { title: this.$t('m.reservation_varietiy_details.place_of_dispatch'), cont: '' },
        { title: this.$t('m.reservation_varietiy_details.delivery_points'), cont: '' }
      ], // 详情头部信息
      datalist: reactive({}),
      // comList: reactive({})

      // 聊天窗口信息
      productInfo: {}
    }
  },
  components: { HeaderBase, Footer, CreateChat },
  watch: {
    'thisRightTest.price' (v, oldv) {
      this.allPrice = (v * this.thisRightTest.stock) / 100 * 20
    },
    'thisRightTest.stock' (v, oldv) {
      this.allPrice = (v * this.thisRightTest.price) / 100 * 20
    }
  },
  computed: {
    daikuan: {
      get () {
        return (this.allPrice / 100) * (100 - this.thisRightTest.fukuanvalue)
      }
    }
  },
  methods: {
    async initData () {
      const id = this.$route.query.id
      try {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...'
        })
        // const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
        const userId = (getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{') ? JSON.parse(getStore('usermessage')) : null

        const data = {
          productId: id,
          userId: userId ? userId.id : null
        }
        const res = await pmsexchangeproduct(data)
        console.log(res)
        if (res.data.code === 200) {
          // console.log(res.data.data)
          this.detailData = res.data.data
          this.companyInfo = this.detailData.companyInfo
          this.product = this.detailData.product
          this.productInfo = this.detailData.product
          this.skuStockList = this.detailData.skuStockList[0]
          this.currencySymbol = this.product.currencySymbol // 货币单位

          this.commMess[0].cont = this.product.brandName
          this.commMess[1].cont = this.product.createTime
          // this.commMess[2].cont = this.product.description
          this.commMess[3].cont = this.product.deliveryPlace === null ? '上海市' : this.product.deliveryPlace.slice(0, 3)
          this.commMess[4].cont = this.product.deliveryPlace === null ? '上海市' : this.product.deliveryPlace.slice(0, 3)
          this.bannerImg = []
          this.bannerImg = this.product.albumPics.split(',')
          // console.log()
          console.log(this.detailData)
          loading.close()
          return
        }
        loading.close()
      } catch (error) {
        console.log(error)
      }
    },
    async toBuyPage () { // 去摘单
      const loading = this.$loading({
        lock: true,
        text: '摘单中...'
      })
      console.log(this.detailData)
      loading.close()
      const data = {
        productId: this.product.id,
        productQuantity: this.product.stock,
        productSkuId: this.skuStockList.id,
        orderType: 11
      }

      const res = await generateExchangeOrder(data)
      console.log(res)
      if (res.data.code === 200) {
        ElMessage.success({ message: '摘单成功' })
        try {
          setTimeout(() => {
            // console.log(typeof data[0].resaleFlag)
            this.$router.push('/personalcenter/myorder')
            // window.open(this.$router.href)
            loading.close()
          }, 400)
        } catch (error) {
          console.log(error)
        }
      } else if (res.data.msg === '该用户没有与能够摘单的商品，请添加') {
        ElMessage.error({
          message: res.data.msg
        })
        setTimeout(() => {
          this.$router.push('/merchantworkbench')
        }, 1000)
      } else {
        ElMessage.error({
          message: res.data.msg
        })
      }
    },
    // 拟预估价格
    async niprice () {
      this.loading = true
      // this.skuStockList.exchangeRatio
      // console.log(this.skuStockList.exchangeRatio)
      const bli = this.skuStockList.exchangeRatio.split(':')
      // console.log(typeof bli[0])
      const arr = []
      bli.forEach(item => {
        item = Number(item)
        arr.push(item)
      })
      // console.log(arr)
      const bilibili = arr[0] / arr[1]
      // bli = Number(bli)
      console.log(bilibili)

      if (bilibili * this.thisRightTest.stock >= this.product.stock) {
        ElNotification({
          title: this.$t('m.barter_detail.Predict_whether_the_goods_will_be_traded'),
          message: this.$t('m.barter_detail.You_can_barter_successfully'),
          type: 'success',
          duration: 3000
        })
      } else {
        ElNotification({
          title: this.$t('m.barter_detail.Predict_whether_the_goods_will_be_traded'),
          message: this.$t('m.barter_detail.Possible_unsuccessful_barter'),
          type: 'warning',
          duration: 3000
        })
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    async likeVarietiy () { // 关注商品
      const data = {
        previewStatus: 3,
        productId: this.$route.query.id,
        productSn: null
      }
      const res = await followTheProduct(data)
      console.log(res)
      if (res.data.code === 200) {
        ElMessage.success({
          message: res.data.msg
        })
      } else {
        ElMessage.warning({
          message: res.data.msg
        })
      }
    },
    async confirmEvent () { // 关注供应商
      // console.log('confirm!')
      const data = {
        merchantId: this.companyInfo.memberId
      }
      if (this.product.focusOnMerchant === 1) {
        const res = await unpmsfollowthestore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: '取关成功'
          })
          this.product.focusOnMerchant = 0
        } else {
          ElMessage.error({
            message: res.data.msg
          })
        }
      } else {
        const res = await followTheStore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: '关注成功!'
          })
          this.product.focusOnMerchant = 1
        } else {
          ElMessage.error({
            message: res.data.msg
          })
        }
      }
    },
    cancelEvent () {
      // 取消操作供应商
    }
  },
  created () {
    this.initData()
  },
  setup (props, ctx) {
    window.document.documentElement.scrollTop = 0 // 渲染 详情组件时让 回到顶部

    const carousel = ref(null)
    onMounted(() => {
      setTimeout(() => {
        carousel.value.setActiveItem(0)
      }, 500)
    })
    return {
      data: ref(''),
      carousel
    }
  }
}
</script>

<style lang="scss">
@import '../../style/viriables.scss';

#page{
  background: #F2EFED;
  width: 100%;
  // height: 100%;
  .main{
    width: 1200px;
    margin: 0 auto;
    font-family: Microsoft YaHei;
    font-weight: 400;
    .header{
      margin-top: 10px;
      width: 1200px;
      // height: 400px;
      background: #FFFFFF;
      display: flex;
      .left_img{
        padding: 20px;
        width: 400px;
        .el-carousel{
          height: 400px;
          .el-carousel__container{
            height: 400px;
            .el-carousel__item{
              height: 100%;
              img{
                height: 400px !important;
                width: 100% !important;
              }
            }
          }
        }
      }
      .center_car{
        flex: 1;
        display: flex;
        flex-flow: column;
        padding: 20px;
        border-right: 1px solid #f2f2f2;
        border-left: 1px solid #f2f2f2;

        .center_title{
          display: block;
          .yushouCont{
            float: left;
            min-width: 60px;
            padding: 0 5px;
            height: 20px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            line-height: 20px;
            background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
            border-radius: 4px;
            margin-right: 5px;
          }
          .titlefont{
            font-size: 18px;
            color: #333333;
          }
        }
        .el-form{
          padding: 0;
          .el-form-item{
            padding: 0 20px;
            margin-bottom: 0;
            // height: 40px;
            .el-form-item__label{
              text-align-last: justify;
              text-align: left;
              color: #666666;
            }
            .el-form-item__content{
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .saleclass{
                font-size: 20px;
                font-weight: bold;
                color: #C6824E;
              }
              .stock_num{
                color: #333333;
              }
              .zhuanshouspan{
                // font-size: 16px;
                color: #C6824E;
                text-align: center;
                i{
                  text-align: center;
                  // font-size: 18px;
                  color: rgba(248, 3, 3, 0.808);
                }
              }
            }
          }
          .itemdiv{
            height: 40px;
            background: #FFEEE0;
            padding: 0 20px;
            .itemdiv_span{
              font-size: 14px;
              line-height: 40px;
              color: #C6824E;
              // strong{
              //   // font-size: 16px;
              // }
            }
          }
        }
        .btnspan{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .btn_div{
            float: right;
            width: 40%;
            position: relative;
            height: 52px;
            background: url('../../static/imges/42559e2c11e8b82ba91e451581be42b.png') no-repeat;
            .gobutton{
              position: absolute;
              top: 12px;
              font-size: 14px;
              height: 30px;
              background: transparent;
              border-radius: 4px;
            }
            .gz{
              left: 33px;
              color: #673000;
            }
            .gm{
              left: 107px;
              color: #F2EFED;
            }
          }
        }
      }
      .right_test{
        width: 259px;
        .righttitle{
          height: 58px;
          background: #C6824E;
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 58px;
          text-align: center;
        }
        .right_form{
          margin: 40px 0;
          .el-form-item{
            margin-bottom: 0;
            .el-form-item__label{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space:nowrap;
            }
            .el-form-item__content{
              .el-input--mini{
                width: 145px;
              }
            }
          }
        }
        .jisuan{
          padding: 0 15px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          .gobutton{
            float: right;
            color: #FFFFFF;
            min-width: 120px;
            height: 40px;
            background: linear-gradient(0deg, #280E01 0%, #673000 100%);
            border-radius: 4px;
          }
        }
        .heji{
          text-align: right;
          padding: 0 15px;
          font-size: 14px;
          color: #333333;
          span{
            font-size: 20px;
          }
        }
      }
    }
    .detail{
      margin-top: 10px;
      display: flex;
      .supplier_message{
        width: 240px;
        height: 530px;
        background: #FFFFFF;
        border: 1px solid #C6824E;
        padding: 0 20px;
        display: flex;
        flex-flow: column;
        h3{
          text-align: center;
          padding: 20px;
          font-size: 20px;
          font-weight: bold;
          background: linear-gradient(0deg, #280E01 0%, #673000 100%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          border-bottom: 2px solid #2B1001;
        }
        .supname{
          margin-top: 18px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
        .supgood{
          margin-top: 18px;
          font-size: 12px;
          color: #C6824E;
        }
        .supxiangxi{
          // flex: 1;
          margin: 20px 0;
          .xxdiv{
            display: flex;
            align-items: center;
            color: #666666;
            margin-top: 18px;
            font-size: 14px;
            line-height: 21px;
            .el-icon{
              margin-right: 5px;
              // margin-top: 3px;
            }
            .el-button{
              width: 80px;
              border: 1px solid #EAEAEA;
              color: #666666;
            }
          }
          .suplogo{
            width: 100%;
            img{
              width: 200px;
              // height: 200px;
              border-radius: 50%;
            }
          }
        }
      }
      .supplier_commodity{
        background: #FFFFFF;
        flex: 1;
        margin-left: 10px;
        padding: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        h2{
          color: #333333;
          font-size: 20px;
          margin: 10px 0 20px;
        }
        .commodity_message{
          height: 90px;
          box-sizing: border-box;
          background: #f7f7f7;
          padding: 28px 20px;
          .el-breadcrumb{
            display: flex;
            .el-breadcrumb__item{
              flex: 1;
              display: flex;
              .el-breadcrumb__inner{
                flex: 1;
                .com_body{
                  span{
                    display: block;
                    margin-bottom: 12px;
                    color: #333333;
                  }
                  p{
                    color: #999999;
                  }
                }
              }
              .el-breadcrumb__separator{
                margin-right: 15px;
                line-height: 40px;
              }
            }
          }
        }
        .commodity_content{
          .cont_message{
            font-size: 14px;
            color: #666666;
            line-height: 21px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
 .divfooter{
    background: #280E01;
  }
}
</style>
